import { Box } from '@mui/material';
import QRScanAnimation from 'Assets/images/qr_code_scan.webm';
import { useInterval } from 'Scripts/intervalHook';
import QRCode from 'qrcode.react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { classes } from './style.css';


interface QRComponentProps {
    QRCodeText: string;
}

const QRComponent = ({
    QRCodeText
}: QRComponentProps) => {


    const QRAnimationRef = useRef<HTMLVideoElement>(null);

    const [QRCodeShowing, setQRCodeShowing] = useState<boolean>(false);

    useLayoutEffect(() => {

        QRAnimationRef.current.addEventListener('ended', showQRCode)

        return () => {

            QRAnimationRef.current.removeEventListener('ended', showQRCode);
        }

    }, [])

    const showQRCode = () => {

        setQRCodeShowing(true);
    }

    const showQRAnimation = async () => {

        setQRCodeShowing(false);

        try {

            if(QRAnimationRef.current === null) {

                return;
            }

            await QRAnimationRef.current.play();

        } catch (error) {

            console.warn({ error })
        }
    }

    useInterval(() => {

        showQRCode();

    }, !QRCodeShowing ? 5000 : null);


    useInterval(() => {

        showQRAnimation();

    }, QRCodeShowing ? 10000 : null)

    return (
        <Box
            height={600}
            width={600}
            position="relative"
            display="flex">
            <div style={{
                opacity: Number(QRCodeShowing),
                transition: '500ms all',
                position: 'absolute',
                height: 600,
                width: 600,
            }}>
                <QRCode
                    size={540}
                    value={QRCodeText}
                    className={classes.qrCode}
                />
            </div>
            <video
                style={{
                    opacity: Number(!QRCodeShowing),
                    transition: '500ms all',
                    position: 'absolute',
                }}
                ref={QRAnimationRef}
                autoPlay={true}
                muted={true}
                height="600px"
                width="600px">
                <source
                    src={QRScanAnimation}
                    type="video/webm"
                />
            </video>
        </Box>
    )
}

export default QRComponent
