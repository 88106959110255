import { getSlideProperties, getSlidePropertiesByType } from "Components/types/helpers/slideTypeHelper";
import { config } from "Scripts/config";
import { generateString, generateUniqueKey } from "Scripts/keyHelper";
import { sortObjectKeysByProperty } from "Scripts/listHelper";
import { numberizeSlideIndices } from "Scripts/mathHelper";
import { NavigationState, getSortedSlideKeysBySlideIndex } from "Scripts/slideHelper";
import { calculateShapeHorizontalPositions } from "Scripts/slideShapeHelper";
import { trimString } from "Scripts/stringFormatter";
import { ScreenSizes } from "Types/appTypes";
import { LayoutTypes } from "Types/layoutTypes";
import { ContentSlideType, OptionPlaceholder, PresentationState, SlideFile, SlideList, SlideProperties, SlideShapePresets, SlideType, VoteOption, VoteType } from "Types/presentationTypes";
import { SlideAnswer } from "Types/sharedSlideTypes";
import { SlideShape, SlideShapeType, SlideTypes, fixedSlideShapes, staticSlideShapes } from 'Types/slideTypes';
import { cloneDeep, isEmpty } from 'lodash';
import { AnyAction } from "redux";

let initialState: PresentationState = {
	presentationId: null,
	quizTitle: '',
	slides: {},
	errors: null,
	/* These defaults can be overriden in the quiz */
	defaultPointsAwarded: config.defaults.defaultPointsAwarded,
	defaultAnswerTime: config.defaults.defaultAnswerTime,
	defaultShowPercentages: config.defaults.defaultShowPercentages,
	/*
		This contains a list of IDS from slides that require synchronisation
		(The local slides that have changed since last save call)
	*/
	unSyncedSlideIds: [],
	previousSlideNavigationState: null,
	slideNavigationState: NavigationState.BeforeOpen,
	presentationStyleId: null,
	videoURL: '',
	currentPresentationIsTemplate: false,
	currentPresentationisAIGenerated: false,
	previewSlideLayout: undefined,
	updatePresentationTitleLoading: false,
	slideShapePresets: null,
	presentationLogo: null,
	presentationUserLogo: null,
	presentationDefaultLogo: null,
	presentationLogoHidden: null,
	preventAutoSlideMovement: null,
}

export const quizReducer = (state: PresentationState = initialState, action: AnyAction): PresentationState => {

	switch (action.type) {
		case 'DUPLICATE_SLIDE': {

			const {
				slideIdToDuplicate,
				newSlides,
			} = action;

			const { slides } = state;

			const slideToDuplicate = slides[slideIdToDuplicate];

			const currentSlides = Object.values({ ...state.slides }).sort((a, b) => a.slideIndex - b.slideIndex);

			const newUnsyncedSlideIds = [...state.unSyncedSlideIds];

			newSlides.forEach((newSlide, newSlideIndex) => {

				if(newSlide.type === slideToDuplicate.type) {

					newSlide = {
						...slideToDuplicate,
						/* Conditionally populate options */
						...(!isEmpty((slideToDuplicate as VoteType).options) && {
							options: [
								...(slideToDuplicate as VoteType).options as VoteOption[]
							]
						}),
						...(!isEmpty((slideToDuplicate as ContentSlideType).table) && {
							table: [
								...((slideToDuplicate as ContentSlideType).table || []).map(row => ([
									...(row || []).map(cell => ({
										...cell,
										id: generateString(16),
									}))
								]))
							]
						}),
						...(!isEmpty((slideToDuplicate as SlideType).shapes) && {
							shapes: [
								...(slideToDuplicate.shapes || []).map(shape => ({
									...shape,
									slideId: newSlide.id,
								}))
							]
						}),
						id: newSlide.id,
						slideIndex: newSlide.slideIndex,
					}
				}

				currentSlides.splice(newSlide.slideIndex - 1, 0, newSlide);

			})

			currentSlides.forEach((currentSlide, index) => {

				currentSlide.slideIndex = index + 1;

				newUnsyncedSlideIds.push(currentSlide.id);
			})

			const slidesById = {} as Record<number, SlideType>;

			currentSlides.forEach(slide => {

				slidesById[slide.id] = slide;
			})

			return {
				...state,
				slides: slidesById,
				unSyncedSlideIds: newUnsyncedSlideIds,
			}
		}
		case 'SET_PRESENTATION_LOGO': {
			return {
				...state,
				presentationLogo: action.url,
			}
		}
		case 'SET_PRESENTATION_USER_LOGO': {

			return {
				...state,
				presentationUserLogo: action.url,

			}
		}
		case 'SET_SLIDE_SHAPE_PRESETS': {
			return {
				...state,
				slideShapePresets: action.presets
			}
		}
		case 'UPDATE_SLIDE_SHAPE': {

			const shape = action.shape as SlideShape;

			const shapeIndex = action.shapeIndex as number;

			if (!shape) {

				return state;
			}

			if (typeof shape.jss === "object") {

				shape.jss = JSON.stringify(shape.jss);
			}

			const slide = state.slides[shape.slideId];

			if (!slide) {

				return state;
			}

			let slideShape = (slide.shapes || [])[shapeIndex];

			if (!slideShape) {

				return state;
			}

			if (slideShape.type != shape.type) {

				return state;
			}

			slideShape = {
				...slideShape,
				...shape,
			};

			const newShapes = [...(state.slides[shape.slideId].shapes || [])];

			newShapes[shapeIndex] = slideShape;

			/**
			 * Bit of a hacky method of updating slide shapes.
			 * The state is updated internally, but not reflected to the user.
			 * Shapes hold their own state to prevent unneeded re-renders because a ton of
			 * components rely on state.slides and will cause the application to feel sluggish.
			 *
			 * @todo find a way to prevent this
			 */
			state.slides[shape.slideId].shapes = newShapes;

			return state;
		}
		case 'SET_UPDATE_PRESENTATION_TITLE_LOADING': {

			return {
				...state,
				updatePresentationTitleLoading: action.isLoading,
			}
		}
		case 'SET_CURRENT_PRESENTATION_IS_AI_GENERATED': {

			return {
				...state,
				currentPresentationisAIGenerated: action.isAIGenerated,
			}
		}

		case 'SET_PREVIEW_SLIDE_LAYOUT': {
			return {
				...state,
				previewSlideLayout: action.layoutType,
			}
		}
		case 'REORDER_SLIDES': {

			const { sourceIndex, destinationIndex } = action;

			const newSlides: SlideList = { ...state.slides }

			const slideKeys = sortObjectKeysByProperty(newSlides, 'slideIndex').map(Number)

			const [removed] = slideKeys.splice(sourceIndex, 1);

			slideKeys.splice(destinationIndex, 0, removed);

			slideKeys.forEach((key: number, index: number) => {

				newSlides[Number(key)].slideIndex = index + 1
			})

			return {
				...state,
				slides: numberizeSlideIndices({ ...newSlides }),
				unSyncedSlideIds: [
					...state.unSyncedSlideIds,
					...slideKeys,
				]
			}
		}
		case 'SET_SLIDE_NAVIGATION_STATE': {

			let previousState = state.previousSlideNavigationState;
			/* If the new state changed, update the previous state */
			if (action.newState !== state.slideNavigationState) {

				previousState = state.slideNavigationState;
			}

			return {
				...state,
				previousSlideNavigationState: previousState,
				slideNavigationState: action.newState
			}
		}
		case 'RESET_SLIDE_NAVIGATION_STATE': {
			return {
				...state,
				slideNavigationState: NavigationState.BeforeOpen,
				previousSlideNavigationState: null,
			}
		}
		case 'SET_PREVENT_AUTO_MOVEMENT': {

			const { preventAutoMovement } = action;

			return {
				...state,
				preventAutoSlideMovement: preventAutoMovement,
			}
		}
		case 'SET_HIDE_PRESENTATION_LOGO': {

			const { hide } = action;

			return {
				...state,
				presentationLogoHidden: hide,
			}
		}
		case 'SET_PRESENTATION_LOGO_DATA': {

			const {
				presentationLogo,
				presentationUserLogo,
				presentationDefaultLogo,
				presentationLogoHidden,
			} = action;

			return {
				...state,
				presentationLogo,
				presentationUserLogo,
				presentationDefaultLogo,
				presentationLogoHidden,
			}
		}
		case 'SET_PRESENTATION_DATA_FROM_API': {

			const {
				name,
				id,
				slides,
				slideAnswers,
				slideShapes,
				slideTables,
				originPresentationId,
				aiGenerated,
				presentationLogo,
				presentationUserLogo,
				presentationDefaultLogo,
				preventAutoSlideMovement,
				presentationLogoHidden,
			} = action.data;

			const newSlides = {
				...slides
			};

			!isEmpty(slides) && Object.keys(slides).forEach(slideId => {
				if ((slideAnswers || {}).hasOwnProperty(slideId)) {

					newSlides[slideId] = {
						...slides[slideId],
						file: {},
						options: [
							...slideAnswers[slideId].map((option: SlideAnswer, index: number) => ({
								...option,
								answerCode: config.optionKeys[index]
							}))
						],
						shapes: [
							...((slideShapes || {})[slideId] || []).map(shape => ({
								...shape
							}))
						]
					}
				}
			})

			!isEmpty(slideTables) && Object.keys(slideTables).forEach(slideId => {

				if (newSlides.hasOwnProperty(slideId)) {

					newSlides[slideId].table = slideTables[slideId];
				}
			})

			return {
				...state,
				presentationId: id,
				quizTitle: name,
				currentPresentationIsTemplate: Boolean(originPresentationId),
				currentPresentationisAIGenerated: Boolean(aiGenerated),
				presentationLogo,
				presentationUserLogo,
				presentationDefaultLogo,
				presentationLogoHidden,
				preventAutoSlideMovement,
				slides: {
					...numberizeSlideIndices({ ...newSlides }),
				},
			}
		}
		case 'SET_QUESTION_SHOW_AS_PERCENTAGE': {

			const { showPercentages, slideKey } = action

			const newSlides = { ...state.slides }

			newSlides[slideKey] = {
				...newSlides[slideKey],
				showPercentages: Number(showPercentages),
			}

			return {
				...state,
				slides: newSlides,
			}
		}
		case 'UPDATE_SLIDE_PROPERTY': {

			const newSlides = { ...state.slides }

			if (newSlides[action.slideKey]?.hasOwnProperty(action.propertyName)) {

				newSlides[action.slideKey][action.propertyName] = action?.propertyValue;
			}

			return {
				...state,
				slides: newSlides,
			}
		}
		case 'SET_DEFAULT_SHOW_PERCENTAGES': {
			return {
				...state,
				defaultShowPercentages: Number(action.showPercentages),

			}
		}
		case 'SET_DEFAULT_POINTS_AWARDED': {
			return {
				...state,
				defaultPointsAwarded: action.points,
			}
		}
		case 'SET_DEFAULT_ANSWER_TIME': {

			return {
				...state,
				defaultAnswerTime: action.time,
			}
		}
		case 'SET_QUIZ_ERRORS': {
			return {
				...state,
				errors: {
					...action.errors
				}
			}
		}
		case 'CLEAR_SELECTED': {

			return {
				...state,
				presentationId: null,
				quizTitle: config.baseQuizTitle,
				slides: {},
			}
		}
		case 'CLEAR_SLIDES': {

			return {
				...state,
				slides: {},
				presentationId: null,
				quizTitle: config.baseQuizTitle,
				currentPresentationisAIGenerated: false,
			}
		}
		case 'RESET_SLIDE': {

			const newSlides = { ...state.slides }
			const newErrors = { ...state.errors }
			const slideToReset = newSlides[action.slideKey];
			const slideProperties = getSlideProperties(newSlides[action.slideKey]);

			const resetSlide = slideProperties.emptySlide(
				slideToReset.slideIndex,
				slideToReset.id,
				slideToReset.presentationId,
				state
			);

			newSlides[action.slideKey] = resetSlide
			if (newErrors[action.slideKey]) {
				delete newErrors[action.slideKey]
			}

			return {
				...state,
				slides: newSlides,
				errors: newErrors,
			}
		}
		case 'SET_QUIZ_ID': {
			return {
				...state,
				presentationId: action.id
			}
		}
		case 'SET_QUIZ_TITLE': {

			let quizTitle = trimString(action.quizTitle);

			const newErrors = { ...state.errors }

			delete newErrors.needsMinTitleLength

			if (!quizTitle?.length) {

				quizTitle = config.baseQuizTitle
			}

			return {
				...state,
				quizTitle,
				errors: newErrors,
			}
		}
		case 'REMOVE_SLIDE': {

			const newSlides = { ...state.slides }

			const newSlideErrors = { ...(state.errors || {}) }

			delete newSlides[action.slideKey];

			/* Remove any errors when the slide is deleted */
			if (!isEmpty(newSlideErrors[action.slideKey])) {

				delete newSlideErrors[action.slideKey];
			}

			const sortedSlideKeys = getSortedSlideKeysBySlideIndex(newSlides);

			sortedSlideKeys.forEach((slideKey: number, index) => {

				newSlides[slideKey].slideIndex = index + 1
			})

			return {
				...state,
				slides: newSlides,
				errors: newSlideErrors,

			}
		}
		case 'SET_QUESTION_TIME': {

			let newSlides = { ...state.slides }
			const fixedTime = Math.max(config.minQuestionTime, Math.min(action.time, config.maxQuestionTime))
			newSlides[action.slideKey].dynamicTimerLimit = fixedTime
			const newErrors = { ...state.errors }
			delete newErrors[action.slideKey]?.needsValidPoints

			return {
				...state,
				slides: newSlides,
				errors: newErrors,
			}
		}
		case 'SET_QUESTION_POINTS': {

			let newSlides = { ...state.slides }
			const { minQuestionPoints, maxQuestionPoints } = config;
			if (action.points >= minQuestionPoints && action.points <= maxQuestionPoints) {
				newSlides[action.slideKey].points = action.points;
			}
			const newErrors = { ...state.errors }

			return {
				...state,
				slides: newSlides,
				errors: newErrors,
			}
		}
		case 'ADD_NEW_SLIDES': {
			
			const currentSlides = Object.values({ ...state.slides }).sort((a, b) => a.slideIndex - b.slideIndex);

			const newUnsyncedSlideIds = [...state.unSyncedSlideIds];

			const newSlides = action.slides;

			newSlides.forEach((slide: SlideType) => {

				currentSlides.splice(slide.slideIndex - 1, 0, slide);

				currentSlides.forEach((currentSlide, index) => {

					currentSlide.slideIndex = index + 1;

					newUnsyncedSlideIds.push(currentSlide.id);
				})
			})

			const slidesById = {} as Record<number, SlideType>;

			currentSlides.forEach(slide => {

				slidesById[slide.id] = { ...slide };
			})

			return {
				...state,
				slides: { ...slidesById },
				unSyncedSlideIds: newUnsyncedSlideIds,
			}
		}
		case 'ADD_NEW_SLIDE': {

			const newSlides = { ...state.slides }

			const newSlideSlideIndex = action.newSlide.slideIndex;

			Object.keys(newSlides).forEach(slideKey => {

				if (Number(newSlides[Number(slideKey)].slideIndex) >= Number(newSlideSlideIndex)) {
					/* Increment the slides slideIndex if the new slide was put in between */
					newSlides[Number(slideKey)].slideIndex += 1;
				}
			})

			newSlides[action.newSlide.id] = {
				...action.newSlide
			}

			return {
				...state,
				slides: newSlides
			}
		}
		case 'CLEAR_QUESTION_FILE': {

			let newSlides = { ...state.slides };

			newSlides[action.slideKey].file = {} as SlideFile;
			newSlides[action.slideKey].filename = null;
			newSlides[action.slideKey].videoURL = null;

			return {
				...state,
				slides: newSlides
			}
		}
		case 'SET_SLIDES': {

			if (action.addToUnsyncedKeys) {

				const newUnsyncedSlideIds = [...state.unSyncedSlideIds];

				Object.keys(action.slides).map(Number).forEach((slideKey: number) => {

					newUnsyncedSlideIds.push(slideKey);
				})

				return {
					...state,
					slides: {
						...action.slides
					},
					unSyncedSlideIds: newUnsyncedSlideIds,
				}
			}

			return {
				...state,
				slides: {
					...action.slides
				}
			}
		}
		case 'UPDATE_SLIDE': {

			const newSlides = { ...state.slides }

			const slideProperties: SlideProperties = getSlideProperties(action.slide);

			const emptySlide = slideProperties.emptySlide(
				action.slide.slideIndex,
				action.slide.id,
				action.slide.presentationId,
				state,
			);

			newSlides[action.slide.id] = {
				...emptySlide,
				title: action.slide.title,
				content: action.slide.content,
				options: [
					...(action.slide.options || []).filter(Boolean)
				],
				table: [
					...(action.slide.table || []).filter(Boolean)
				],
				shapes: [
					...(action.slide.shapes || []).filter(Boolean)
				],
				filename: action.slide.filename,
				slideLayout: action.slide.slideLayout,
				videoURL: action.slide.videoURL,
			}

			return {
				...state,
				slides: {
					...newSlides
				}
			}
		}
		case 'SET_QUESTION_TEXT': {

			let newSlides = { ...state.slides };

			newSlides[action.slideKey].title = action.title;

			return {
				...state,
				slides: newSlides,
			}
		}

		case 'ADD_OPTION': {

			const newSlides = { ...state.slides }

			const newOptions = (newSlides[action.slideKey]?.options || []);

			const optionKey = newOptions?.[action.optionIndex]?.key || generateUniqueKey();

			newOptions[action.optionIndex] = {
				key: optionKey,
				answerCode: config.optionKeys[newOptions.length],
				answer: action.answer,
				correctAnswer: action.correctAnswer,
			}

			newSlides[action.slideKey].options = [
				...newOptions,
			]

			return {
				...state,
				slides: newSlides
			}
		}
		case 'DELETE_OPTION': {

			const newSlideOptions = [...(state.slides[action.slideKey].options || [])] as Array<VoteOption>;

			newSlideOptions.splice(action.optionIndex, 1);

			newSlideOptions.forEach((option, index) => {

				newSlideOptions[index].answerCode = config.optionKeys[index];
				/**
				 * If the option is equal to the default placeholder text for the next option,
				 * Reset it to the default placeholder text for the current option (e.g.: Enter option B -> Enter option A)
				 */
				if (option.answer === OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index + 1])) {

					option.answer = OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index]);
				}
			})

			return {
				...state,
				slides: {
					...state.slides,
					[action.slideKey]: {
						...state.slides[action.slideKey],
						options: [
							...newSlideOptions,
						]
					}
				}
			}
		}
		case 'CLEAR_UNSYNCED_SLIDE_IDS': {

			const newUnsyncedSlideIds = [...state.unSyncedSlideIds];

			if (isEmpty(action.slideIds)) {

				state.unSyncedSlideIds = [];

				return state;

			} else {

				action.slideIds.forEach((slideId: number) => {

					const slideIdIndex = newUnsyncedSlideIds.indexOf(slideId);

					if (slideIdIndex !== -1) {

						newUnsyncedSlideIds.splice(slideIdIndex, 1);
					}
				});
			}

			state.unSyncedSlideIds = newUnsyncedSlideIds;

			return state;
		}
		case 'ADD_TO_UNSYNCED_SLIDE_IDS': {

			const newUnsyncedSlideIds = [...state.unSyncedSlideIds];

			const newUnsyncedSlideId = action.slideKey;
			let newUnsyncedSlideIdFrequency = 0;

			newUnsyncedSlideIds.forEach(slideId => {

				if (newUnsyncedSlideId === slideId) {

					newUnsyncedSlideIdFrequency++;
				}
			})

			/**
			 * Only store a maximum of two instances of a given slidekey.
			 * This prevents filling the array with the same ID causing the autosave to be
			 * called continuously
			 */
			if (newUnsyncedSlideIdFrequency < 2) {

				newUnsyncedSlideIds.push(action.slideKey);
			}

			return {
				...state,
				unSyncedSlideIds: newUnsyncedSlideIds
			}
		}
		case 'CLEAR_QUIZ_STATE': {
			return {
				...initialState
			}
		}
		case 'UPDATE_SLIDE_FILES': {

			const newSlides = { ...state.slides }

			Object.keys(action.fileUrls).forEach((slideKey) => {

				if (!isEmpty(newSlides[Number(slideKey)].file)) {

					newSlides[Number(slideKey)].file = {}
					newSlides[Number(slideKey)].filename = action.fileUrls[slideKey];

				}
			})

			return {
				...state,
				slides: newSlides
			}
		}
		case 'SET_PRESENTATION_STYLE_ID': {
			return {
				...state,
				presentationStyleId: action.styleId,
			}
		}
		case 'SET_SLIDE_TABLE': {

			const { table, slideId } = action;

			return {
				...state,
				slides: {
					...state.slides,
					[slideId]: {
						...state.slides[slideId],
						table: [
							...table
						],
					}
				}
			}
		}
		case 'CONVERT_SLIDE': {

			const slideId = action.slideId;

			const newSlide = { ...state.slides[slideId] };
			/** Layout/type to change to */
			const newSlideType = action.slideType as SlideTypes;
			const newLayoutType = (action.layoutType || "default") as LayoutTypes;
			/** Current layout/type */
			const currentSlideType = newSlide.type as SlideTypes;
			const currentLayoutType = (newSlide.slideLayout || "default") as LayoutTypes;
			/** Clone the presets */
			const presets = cloneDeep({ ...state.slideShapePresets }) as SlideShapePresets;
			/** Check if there are presets available for the new layout/type */
			const availableShapePresets = [...(presets?.[newLayoutType]?.[newSlideType] || [])];
			/** Check if the current layout/type has presets */
			const currentPresets = [...(presets?.[currentLayoutType]?.[currentSlideType] || [])];

			const currentSlideHasShapes = !isEmpty(newSlide.shapes);

			if (Boolean(availableShapePresets?.length)) {

				if (currentSlideHasShapes) {

					/**
					 * Convert shapes to shapes by updating the positioning to that of those known
					 * in the preset.
					 */
					const newSlideShapes = [...availableShapePresets];

					const updatedSlideShapes = newSlideShapes.map((shape) => {

						const matchingShape = newSlide.shapes?.find((currentShape) => currentShape.type === shape.type) as SlideShape;

						if (!Boolean(matchingShape)) {

							return shape;
						};

						const currentPresetShape = (currentPresets || []).find((presetShape => presetShape.type === matchingShape.type)) as SlideShape;

						let newContents = matchingShape.contents;
						let newJss = matchingShape.jss;

						if (Boolean(currentPresetShape)) {
							/** If the current shape contents match whats in the preset or is empty	*/
							if (!`${matchingShape?.contents}`.trim() || currentPresetShape.contents === matchingShape.contents) {
								/** Fill it with the placeholder contents/styles */
								newContents = shape.contents;
								newJss = shape.jss;
							}
						}

						const updatedShape = {
							...matchingShape,
							x: shape.x,
							y: shape.y,
							width: shape.width,
							height: shape.height,
							contents: newContents,
							jss: newJss,
						};

						/** Scale all shapes other than images/video shapes height based on their current height */
						if (!staticSlideShapes.includes(matchingShape.type) && !fixedSlideShapes.includes(matchingShape.type)) {

							const newHeight = Math.min(ScreenSizes.Height1080P, Math.max(Number(matchingShape.height), shape.height));
							updatedShape.height = newHeight;
						}

						return updatedShape;
					});

					/** Re-calculate the shapes y positions */
					const positionedShapes = calculateShapeHorizontalPositions(newSlide.type, updatedSlideShapes as Array<SlideShape>);

					newSlide.shapes = [
						...positionedShapes
					];

				} else {
					/**
					 * Convert a slide to shapes based on a known preset.
					 * Currently we simply add the shapes to the slide
					 * and carry over the title to the text shape contents.
					 */
					const newShapes = [...availableShapePresets];

					newShapes.forEach((shape, index) => {

						if (shape.type === SlideShapeType.Title) {

							newShapes[index].contents = newSlide.title;
							newSlide.title = "";
						}

						if (shape.type === SlideShapeType.Content) {

							newShapes[index].contents = newSlide.content;
							newSlide.content = "";
						}

						newShapes[index].slideId = slideId;
					})

					newSlide.shapes = newShapes;
				}

			} else {

				if (currentSlideHasShapes) {
					/**
					 * Convert a shapes slide to a regular slide.
					 * The contents are migrated to the relevant slide properties
					 * (just title for now until we implement new slide types)
					 */
					(newSlide.shapes || []).forEach(shape => {

						if (shape.type === SlideShapeType.Title) {

							newSlide.title = shape.contents;
						}

						if (shape.type === SlideShapeType.Content) {

							newSlide.content = shape.contents;
						}
					})

					/** Clear the shapes */
					newSlide.shapes = [];

				} else {
					/**
					 * Slide to slide conversion
					 * This is handled by simply adjusting the layoutType/type as seen below
					 */
				}
			}

			/** Update the layout/type */
			newSlide.slideLayout = action.layoutType;
			newSlide.type = action.slideType;

			const newSlideProperties = getSlidePropertiesByType(newSlideType);
			/** Missing properties such as answers, table etc. */
			const newEmptySlideProperties = newSlideProperties.emptySlide(newSlide.slideIndex, newSlide.id, newSlide.presentationId);

			return {
				...state,
				slides: {
					...state.slides,
					[slideId]: {
						...newEmptySlideProperties,
						...newSlide,
					},
				}
			}
		}
		default: {
			return state
		}
	}
}