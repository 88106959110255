import Fade from '@mui/material/Fade';
import DarkTransitionAnimation from 'Components/play/templates/geometry_template/animations/components/dark/DarkTransitionAnimation';
import ParticipantCounter from 'Components/play/templates/geometry_template/shared_components/participant_counter/ParticipantCounter';
import GeometrySessionDetails from 'Components/play/templates/geometry_template/slides/explanation_slide/session_details/GeometrySessionDetails';
import SessionLoader from 'Components/session/SessionLoader';
import ParticipantJoinContainer from 'Components/session/participant_container/ParticipantJoinContainer';
import { useSession } from 'Hooks/useSession';
import { AppState } from 'Types/appTypes';
import { PlayState } from 'Types/playTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import { classes } from './style.css';

export enum ViewType {
	EDITOR = "EDITOR",
	PLAY = "PLAY"
};

interface IExplanationSlideShapeComponent {
	from: ViewType
};

const ExplanationSlideShapeComponent = ({from}: IExplanationSlideShapeComponent) => {
	const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);
	const liveWebsocket = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).liveWebsocket);
	const {userSessionInfo} = useSession();

	return (
		<Fade timeout={{ enter: 250 }} in={true}>
			<div className={classes.fadeWrapper}>
				{from == ViewType.PLAY &&  <ParticipantJoinContainer />}
				{from == ViewType.PLAY && <DarkTransitionAnimation show={false} />}
				<div className={classes.wrapper}>
					{!(sessionData && liveWebsocket) && from !== ViewType.EDITOR && <SessionLoader error={!(sessionData && liveWebsocket)} />}
					{(sessionData && liveWebsocket) && from !== ViewType.EDITOR ?
						<GeometrySessionDetails
							sessionDetails={sessionData}
						/>:
						<GeometrySessionDetails
							sessionDetails={{ internetAddress: userSessionInfo?.responseUrl, internetKeyword: userSessionInfo?.responseCode }}
						/>
					}
				</div>
				<div className={classes.participantCounterBox}>
					<ParticipantCounter />
				</div>
			</div>
		</Fade>
	);
};

export default ExplanationSlideShapeComponent;