import { Box } from '@mui/material';
import AppSvgIcon, { AppSvgIconType } from 'Atomic/atoms/Svg/AppSvgIcon';
import AppText from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { getMUISafeAlphaColor } from 'Scripts/colorHelper';
import { numberWithCommas } from 'Scripts/stringFormatter';
import React from 'react';
import { classes } from './style.css';

interface ParticipantCounterProps {
	showTooltip?: boolean;
}

const ParticipantCounter = ({ showTooltip = false }: ParticipantCounterProps) => {

	const { translate } = useTranslations();

	const { presentationStyle } = usePresentationStyles();

	const { displayedParticipantCount } = useSession();

	return (
		<CustomTooltip
			open={showTooltip ? undefined : false}
			title={translate("PARTICIPANT_COUNTER_EXPLANATION")}>
			<Box
				className={classes.participantCounter}
				sx={{
					background: getMUISafeAlphaColor(presentationStyle?.darkColor || '#fff', .5),
					'& svg': {
						stroke: presentationStyle?.textColor,
						pr: 1
					}
				}}>
				<AppText
					color={presentationStyle?.textColor}
					fontSize={30}
					fontWeight={600}
					display="flex"
					alignItems="center"
				>
					<AppSvgIcon type={AppSvgIconType.Participant} /> {numberWithCommas(displayedParticipantCount)}
				</AppText>
			</Box>
		</CustomTooltip>

	)
}

export default ParticipantCounter
