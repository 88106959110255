import usePresentationStyles from 'Hooks/usePresentationStyles';
import { Participant } from 'Types/playTypes';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import ParticipantItem from '../participant/ParticipantItem';
import { classes } from './style.css';


declare global {
    interface Window {
        floatDelay?: number;
        floatParticipant?(participant: Participant): void;
    }
}

const showDelay = 500;

const ParticipantJoinContainer = () => {

    const { presentationStyle } = usePresentationStyles();

    const getParticipantElement = useCallback((participant: Participant) => {

        return (
            <ParticipantItem
                participant={participant}
                textColor={presentationStyle?.textColor as string}
            />
        );

    }, [presentationStyle])

    const participantContainerRef = useRef<HTMLDivElement>(null);

    const debouncedFloatParticipant = (participant: Participant) => {

        if (!participantContainerRef.current) {

            return;
        }

        const portalElement = document.createElement('div');

        ReactDOM.render(getParticipantElement(participant), portalElement);

        window.floatDelay = (window.floatDelay || 0) + showDelay;

        /** Add the element to the container after the floatDelay has passed */
        const spawnTimeout = setTimeout(() => {
            /** Subtract the showDelay from the floatDelay */
            window.floatDelay = (window.floatDelay || 0) - showDelay;

            participantContainerRef.current?.appendChild(portalElement);

            clearTimeout(spawnTimeout);
            /** Remove the item from the container after 10s */
            const despawnTimeout = setTimeout(() => {

                participantContainerRef.current?.removeChild(portalElement);

                clearTimeout(despawnTimeout);

            }, 10e3);

        }, window.floatDelay);
    };

    /**
     * Init & cleanup
     */
    useEffect(() => {

        window.floatDelay = 0;

        window.floatParticipant = debouncedFloatParticipant;

        return () => {

            window.floatDelay = undefined;

            window.floatParticipant = undefined;
        }

    }, [participantContainerRef.current]);

    return (
        <div id="participant-container" className={classes.participantContainer} ref={participantContainerRef} />
    );
}


export default ParticipantJoinContainer;