import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Backdrop, Fade, Popover } from '@mui/material';
import { Box } from '@mui/system';
import { TestIdAttributes } from 'Scripts/measurementsGAHelper';
import React, { useState } from 'react';
import AppButton, { AppButtonProps } from '../Button/AppButton';
import { classes } from './style.css';


export enum AppDropdownButtonVariant {
    Primary = 'Primary',
    Info = 'Info',
    Light = 'Light',
    Secondary = 'Secondary',
    Success = 'Success',
    Warning = 'Warning',
    Plain = 'Plain',
    Error = 'Error',
    Outlined = 'Outlined',
    Underline = 'Underline',
    Text = 'Text',
    Square = 'Square',
}

interface AppDropdownButtonProps extends Omit<AppButtonProps, "onClick">, TestIdAttributes {
    children?: any;
    hideDropdownIcon?: boolean;
    closeOnClickedDropdown?: boolean;
    dropdownComponent: React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
}

const AppDropdownButton = React.forwardRef<HTMLSpanElement, AppDropdownButtonProps>((props: AppDropdownButtonProps, ref) => {

    const {
        children,
        hideDropdownIcon,
        dropdownComponent,
        closeOnClickedDropdown,
        onOpen,
        onClose,
        ...buttonProps
    } = props;

    const [popupAnchorElement, setPopupAnchorElement] = useState<HTMLSpanElement | null>(null);


    const handleClicked = (e: any) => {

        onOpen?.();

        setPopupAnchorElement(e.currentTarget as HTMLAnchorElement);
    }

    const handleClose = () => {

        onClose?.();

        setPopupAnchorElement(null);
    }

    return (
        <>
            <AppButton
                ref={ref}
                {...buttonProps}
                onClick={handleClicked}
                endIcon={hideDropdownIcon ? (buttonProps?.endIcon) : (
                    <Box className={classes.dropdownIconContainer} >
                        <KeyboardArrowDownIcon
                            className={classes.arrowIcon}
                            sx={{
                                transform: Boolean(popupAnchorElement) ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        />
                    </Box>
                )}
            >
                {children}
            </AppButton>
            <Popover
                open={Boolean(popupAnchorElement)}
                anchorEl={popupAnchorElement}
                onClick={closeOnClickedDropdown ? handleClose : undefined}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                TransitionComponent={Fade}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                {dropdownComponent}
            </Popover>
            <Backdrop className={classes.backdrop} open={Boolean(popupAnchorElement)} />
        </>
    )
});

export default AppDropdownButton;