import { matchPath } from "react-router-dom";

export interface SessionRouteParams {
    presentationId?: number;
    currentSlideKey?: number;
    nextSlideKey?: number;
    prefix?: string;
}

export enum SessionRoutePrefix {
    Play    = 'play',
    Preview = 'preview',
}

export enum SessionRouteMatches {
    Intro    = `/:prefix/:presentationId/intro`,
    ActiveSlide         = `/:prefix/:presentationId/:currentSlideKey`,
    SlideRedirect       = `/:prefix/:presentationId/:currentSlideKey/to/:nextSlideKey`,
    PodiumSlide         = `/:prefix/:presentationId/end/podium`,
}

export const useSessionRoutes = () => {

    const isPreviewSession = window.location.pathname.includes(SessionRoutePrefix.Preview);

    const isInPlaySession = window.location.pathname.includes(SessionRoutePrefix.Play);

    const isInSession = (isPreviewSession || isInPlaySession);

    /**
     * If passing multiple prefixes, will group them together in regex format.
     *
     * Otherwise will just return the prefix;
     *
     * e.g.: ["prefixA", "prefixB"] => "(prefixA|prefixB)"
     *
     * @param prefix
     */
    const getPrefix = (prefix: string | string[]) => {

        if (Array.isArray(prefix)) {

            return `(${prefix.join('|')})`;
        }

        return prefix;
    }

    const getRoute = (routeParams: SessionRouteParams, routeMatch: SessionRouteMatches) => {

        let formattedMatch = `${routeMatch}`;

        if (!routeParams.prefix) {

            if (isPreviewSession) {

                routeParams.prefix = SessionRoutePrefix.Preview;

            } else {

                routeParams.prefix = SessionRoutePrefix.Play;
            }
        }

        Object.keys(routeParams).forEach(routeParamKey => {

            formattedMatch = formattedMatch.replace(`:${routeParamKey}`, routeParams[routeParamKey]);
        })

        return formattedMatch;
    }

    const getRouteMatch = (prefix: SessionRoutePrefix | Array<SessionRoutePrefix>, routeMatch: SessionRouteMatches) => {

        const prefixString = getPrefix(prefix);

        return getRoute({ prefix: prefixString }, routeMatch);
    }

    /**
     * We want to be able to extract route match params such as
     * a presentationId or currentSlideKey from the routes
     * so we map the known prefixes to a matching route
     */
    const sessionRoutePrefixes = [
        SessionRoutePrefix.Preview,
        SessionRoutePrefix.Play,
    ];

    const match = matchPath(window.location.pathname, {
        strict: false,
        path: [
            /**
             * Order of these is important, since it will pick the first match.
             *
             * "/:presentationId" will be matched before "/:presentationId/:slideKey"
             * meaning :slideKey won't match and will default to null.
             */
            getRouteMatch(sessionRoutePrefixes, SessionRouteMatches.SlideRedirect),
            getRouteMatch(sessionRoutePrefixes, SessionRouteMatches.ActiveSlide),
            getRouteMatch(sessionRoutePrefixes, SessionRouteMatches.PodiumSlide),
            getRouteMatch(sessionRoutePrefixes, SessionRouteMatches.Intro),
            getRouteMatch(sessionRoutePrefixes, SessionRouteMatches.ActiveSlide),
        ],
    })

    const matchParams = (match?.params || {}) as SessionRouteParams;

    const presentationId = Number(matchParams.presentationId) || null;

    const currentSlideKey = Number(matchParams.currentSlideKey) || null;

    const nextSlideKey = Number(matchParams.nextSlideKey) || null;

    return {
        isPreviewSession,
        isInPlaySession,
        isInSession,
        presentationId,
        currentSlideKey,
        nextSlideKey,
        getRouteMatch,
        getRoute,
    };
}