import { Box, Grid, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppButtonVariant } from "Atomic/atoms/Button/AppButton";
import { AppTextVariant } from "Atomic/atoms/Text/AppText";
import BackToOverviewButton from "Components/beta/common/buttons/back/BackToOverviewButton";
import HeaderIcon from "Components/beta/common/icons/header/icon/HeaderIcon";
import ProfileIcon from "Components/beta/content/content_header/profileicon/ProfileIcon";
import ThreeColumnsLayout from "Components/layouts/threeColumnsLayout/ThreeColumnsLayout";
import { themeVars } from "Components/theme/vanillaTheme.css";
import React from "react";
import { classes } from "./style.css";

interface IHeader {
    logo?: boolean;
    backToMyLibrary?: boolean;
    children?: React.ReactElement;
    leftContent?: React.ReactElement;
    rightContent?: React.ReactElement;
}

const headerColumnsSizeConfig = {
    leftColumn: {
        xs: 4,
        sm: 4,
        lg: 4,
        xl: 4,
    },
    centerColumn: {
        xs: 4,
        sm: 3,
        lg: 3,
        xl: 3,
    },
    rightColumn: {
        xs: 4,
        sm: 5,
        lg: 5,
        xl: 5,
    },
};

const Header = ({logo = false, backToMyLibrary = false, children = <></>, rightContent = <></>, leftContent = <></>}: IHeader) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Box className={classes.container}>
            <ThreeColumnsLayout
                bgColor={themeVars.color.tertiaryBg}
                columnsConfig={headerColumnsSizeConfig}
                leftContent={
                    isSmallScreen
                        ?
                            <Box className={classes.columnWrapper}>
                                <Grid justifyContent={"space-between"} container>
                                    {backToMyLibrary && <Grid item><BackToOverviewButton as={AppButtonVariant.Primary}/></Grid>}
                                    {leftContent}
                                </Grid>
                                </Box>
                        :
                            <Box className={classes.columnWrapper}>
                                <Grid container direction="row">
                                    {backToMyLibrary && <div className={classes.backToOverviewButtonContainer}><BackToOverviewButton as={AppButtonVariant.Primary}/></div>}
                                    {leftContent}
                                    {logo && <HeaderIcon />}
                                </Grid>
                            </Box>
                }
                rightContent={
                    <Box className={classes.columnWrapper}>
                        <Grid display={"flex"} flexDirection={"row-reverse"}>
[                            <ProfileIcon as={AppTextVariant.PrimaryBgSmall}/>
                            {rightContent}
                        </Grid>
                    </Box>
                    }
            >
                <Box className={classes.columnWrapper}>
                    {children}
                </Box>
            </ThreeColumnsLayout>
        </Box>
    );
}
export default Header;