import { Slide } from "Types/presentationTypes";
import { SlideTypes } from "Types/slideTypes";

export const interactiveSlideTypesArr = [
    SlideTypes.MultipleChoice,
    SlideTypes.Quiz,
    SlideTypes.Wordcloud,
    SlideTypes.QA,
    SlideTypes.OpenEnded,
    SlideTypes.Points,
    SlideTypes.Explanation,
    SlideTypes.Podium,
    SlideTypes.Leaderboard,
];
export const notInteractiveSlideTypesArr = [
    SlideTypes.Content,
    SlideTypes.Title,
    SlideTypes.Image,
    SlideTypes.Video,
    SlideTypes.Quote,
    SlideTypes.Countdown,
    SlideTypes.TitleAndSubtitle,
    SlideTypes.Table,
    SlideTypes.LineGraph,
    SlideTypes.BarGraph,
];

export const isInteractiveSlide = (slideType: SlideTypes) => {
    if(slideType == SlideTypes.Explanation) return false;
    return interactiveSlideTypesArr.includes(slideType);
};

export const itContainAnInteractiveSlide = (slides: Slide[]) => {
    return slides.some((slide) => isInteractiveSlide(slide.type));
};


