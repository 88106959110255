import { Box } from '@mui/material';
import AppText from 'Atomic/atoms/Text/AppText';
import { getRandomColor } from 'Scripts/colorHelper';
import { Participant } from 'Types/playTypes';
import React from 'react';
import { classes } from './style.css';

interface ParticipantItemProps {
    participant: Participant;
    textColor: string;
}

const ParticipantItem = ({ participant, textColor }: ParticipantItemProps) => {

    return (
        <div
            key={participant.participantId}
            className="animate-participant">
            <Box display="flex" alignItems="center" >
                <div className={classes.participantItem} style={{
                    border: `2px solid ${textColor}`,
                    backgroundColor: getRandomColor(),
                }} />
                <AppText
                    fontSize={32}
                    pl={2}
                    color={textColor}>
                    {participant.name}
                </AppText>
            </Box>

        </div>
    );
};

export default ParticipantItem;