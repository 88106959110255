import { Link, Typography } from '@mui/material';
import SendstepsLogo from 'Components/common/dialogs/shared/image/logo/SendstepsLogo';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import { Features, useFeatures } from 'Hooks/useFeatures';
import useUrl from 'Hooks/useUrl';
import { testIds } from 'Scripts/cypressTestIds';
import { AppState } from 'Types/appTypes';
import { FeatureState } from 'Types/featureTypes';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';


const HeaderIcon = () => {

    const userBrandingData = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).userBrandingData);

    const userBrandingLoading = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).userBrandingLoading);

    const availableFeatures = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).availableFeatures) || [];

    const planFeaturesLoading = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planFeaturesLoading);

    const { isFeatureAvailable } = useFeatures();

    const hasCustomLogo = useMemo(() => {

        return userBrandingData?.logo && isFeatureAvailable(Features.WEB_SHOW_COMPANY_LOGO);

    }, [userBrandingData, availableFeatures])

    const urls = useUrl();

    const history = useHistory();

    const goToPresentationOverview = () => {
        history.push(urls.presentationOverview);
    }

    return <div className={classes.leftDrawerLogoWrapper}>
        {(userBrandingLoading || planFeaturesLoading) && <ButtonLoader />}
        {!(userBrandingLoading || planFeaturesLoading) && <>
            {!hasCustomLogo ? (
                <div onClick={goToPresentationOverview} >
                    <SendstepsLogo />
                </div>
            )
                :
                <img
                    className={classes.leftDrawerLogo}
                    src={userBrandingData?.logo}
                    data-testid={testIds.HEADER_LOGO}
                    data-default-logo={!hasCustomLogo}
                    onClick={goToPresentationOverview}
                />
            }
            {userBrandingData &&
                userBrandingData.logo !== null &&
                isFeatureAvailable(Features.WEB_SHOW_COMPANY_LOGO) &&
                <>
                    <Typography
                        variant="h6"
                        className={classes.poweredBySendsteps}>
                        Powered by <span
                            data-eltype="link"
                            data-elaction="to_sendsteps_website"
                            data-elposition="powered_by_section"
                            data-elscreen="leftdrawer">
                            <Link
                                target="_sendsteps"
                                href={process.env.WEBSITE_URL}>
                                Sendsteps
                            </Link>
                        </span>
                    </Typography>
                </>}
        </>}
    </div>
};

export default HeaderIcon;