import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppDialogCloseIcon from 'Atomic/atoms/DialogClose/AppDialogCloseIcon';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import { useTranslations } from 'Hooks/useTranslations';
import { TestIDs } from 'Scripts/cypressTestIds';
import React, { ReactElement } from 'react';
import { classes } from './style.css';

interface ITwoCtaDialog {
	isOpen: boolean;
    close: ()=> void;
    title: string; 
    leftCtaIcon:  ReactElement;
    leftCtaLabel: string; 
    leftCtaFc:(index?: number | undefined)=> void;
	subTitle?: string; 
    rightCtaIcon?:  ReactElement;
    rightCtaLabel?: string;  
    rightCtaFc?:()=> void;
	leftCtaVariant?: AppButtonVariant;
	rightCtaVariant?: AppButtonVariant;
	dataTestIdLeftCta?: TestIDs; 
	dataElactionLeftCta?: string;
	dataElpositionLeftCta?: string;
	dataElscreenLeftCta?: string;
	dataEltypeLeftCta?: string;
	dataTestIdRightCta?: TestIDs; 
	dataElactionRightCta?: string;
	dataElpositionRightCta?: string;
	dataElscreenRightCta?: string;
	dataEltypeRightCta?: string;
	dataTestIdCloseCta?: TestIDs;
	dataElactionCloseCta?: string;
	dataElpositionCloseCta?: string;
	dataElscreenCloseCta?: string;
	dataEltypeCloseCta?: string;
}

const TwoCtaDialog = ({
    isOpen,
    close, 
    title, 
    leftCtaIcon, 
    leftCtaLabel, 
    leftCtaFc,
	subTitle,
    rightCtaIcon = <CloseIcon />, 
    rightCtaLabel, 
    rightCtaFc = close,
	leftCtaVariant =  AppButtonVariant.Error,
	rightCtaVariant = AppButtonVariant.Outlined,
	dataTestIdLeftCta, 
	dataElactionLeftCta = "",
	dataElpositionLeftCta = "",
	dataElscreenLeftCta = "",
	dataEltypeLeftCta = "",
	dataTestIdRightCta,
	dataElactionRightCta = "",
	dataElpositionRightCta = "",
	dataElscreenRightCta = "",
	dataEltypeRightCta = "",
	dataTestIdCloseCta, 
	dataElactionCloseCta = "",
	dataElpositionCloseCta = "",
	dataElscreenCloseCta = "",
	dataEltypeCloseCta = "",
}: ITwoCtaDialog) => {
	const { translatePlaceholder } = useTranslations();
	
	return (
		<Dialog 
            onClose={() => close()} 
            open={isOpen} 
            PaperProps={{ className: classes.dialog }}
        >
			<AppDialogCloseIcon
				onClose={close}
				data-testid={dataTestIdCloseCta}
				data-elaction={dataElactionCloseCta}
				data-elposition={dataElpositionCloseCta}
				data-elscreen={dataElscreenCloseCta}
				data-eltype={dataEltypeCloseCta}
			/>
			<DialogContent>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.titleSubtitleContainer}>
						<AppText  className={classes.title} paddingRight={"20px"} as={AppTextVariant.H5Regular}>
							{title}
						</AppText>
						{subTitle && <AppText as={AppTextVariant.BodyRegular} pt={3} pb={4}>
							{subTitle}
						</AppText>}
					</Grid>
					<Grid mt={"16px"} container spacing={2} >
						<Grid item xs={12} md={6}>
							<AppButton
								as={leftCtaVariant}
								onClick={() => leftCtaFc()}
								fullWidth
								startIcon={leftCtaIcon}
								data-testid={dataTestIdLeftCta}
								data-elaction={dataElactionLeftCta}
								data-elposition={dataElpositionLeftCta}
								data-elscreen={dataElscreenLeftCta}
								data-eltype={dataEltypeLeftCta}
							>
								{leftCtaLabel}
							</AppButton>
						</Grid>
						<Grid item xs={12} md={6}>
							<AppButton
								as={rightCtaVariant}
								onClick={() => rightCtaFc()}
								fullWidth
								startIcon={rightCtaIcon}
								data-testid={dataTestIdRightCta}
								data-elaction={dataElactionRightCta}
								data-elposition={dataElpositionRightCta}
								data-elscreen={dataElscreenRightCta}
								data-eltype={dataEltypeRightCta}
							>
								{rightCtaLabel ? rightCtaLabel : translatePlaceholder("CANCEL")}
							</AppButton>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default TwoCtaDialog;