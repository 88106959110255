import { setDialogTypeObj } from "Actions/outlineActions";
import Header from "Components/header/Header";
import HeaderContentLayout from "Components/layouts/headerContentLayout/HeaderContentLayout";
import ThreeColumnsLayout from "Components/layouts/threeColumnsLayout/ThreeColumnsLayout";
import Overview from "Components/outline/Overview/Overview";
import Summary from "Components/outline/Summary/Summary";
import React from "react";
import { useDispatch } from "react-redux";
import OutlineLogicContainer from "../../../../outline/OutlineLogicContainer/OutlineLogicContainer";

const OutlinePage = ()=> {
    const dispatch = useDispatch();
    dispatch(setDialogTypeObj({dialogType: "", index: 0, isOpen: false}));

    return (
            <HeaderContentLayout
                header={<Header backToMyLibrary/>}
                content={
                    <ThreeColumnsLayout
                        leftContent={<Overview/>}
                        rightContent={<Summary/>}
                    >
                        <OutlineLogicContainer/>
                    </ThreeColumnsLayout>
                }
            />
        )
}
export default OutlinePage;