import { AppChartType } from 'Atomic/organisms/Chart/AppChart';
import { SlideType } from 'Types/presentationTypes';
import { SlideShape, SlideShapeType } from 'Types/slideTypes';
import React, { useCallback } from 'react';
import ExplanationSlideShapeComponent, { ViewType } from '../_session/ExplanationSlideShapeComponent/ExplanationSlideShapeComponent';
import { getTransition } from '../slideShapesHelper';
import PreviewGraphSlideShape from './PreviewGraphSlideShape/PreviewGraphSlideShape';
import PreviewImageSlideShape from './PreviewImageSlideShape/PreviewImageSlideShape';
import PreviewLeaderboardSlideShape from './PreviewLeaderboardSlideShape/PreviewLeaderboardSlideShape';
import PreviewLogoSlideShape from './PreviewLogoSlideShape/PreviewLogoSlideShape';
import PreviewMessagePanelSlideShape from './PreviewMessagePanelSlideShape/PreviewMessagePanelSlideShape';
import PreviewMpcOptionsSlideShape from './PreviewMpcOptionsSlideShape/PreviewMpcOptionsSlideShape';
import PreviewPodiumSlideShape from './PreviewPodiumSlideShape/PreviewPodiumSlideShape';
import PreviewQuizCountdownSlideShape from './PreviewQuizCountdownSlideShape/PreviewQuizCountdownSlideShape';
import PreviewQuoteAuthorSlideShape from './PreviewQuoteAuthorSlideShape/PreviewQuoteAuthorSlideShape';
import PreviewQuoteSymbolSlideShape from './PreviewQuoteSymbolSlideShape/PreviewQuoteSymbolSlideShape';
import PreviewTableSlideShape from './PreviewTableSlideShape/PreviewTableSlideShape';
import PreviewTextSlideShape from './PreviewTextSlideShape/PreviewTextSlideShape';
import PreviewVideoSlideShape from './PreviewVideoSlideShape/PreviewVideoSlideShape';
import PreviewWordcloudSlideShape from './PreviewWordcloudSlideShape/PreviewWordcloudSlideShape';

interface Props {
    shape: SlideShape;
    slide: SlideType;
    animationDuration?: number;
}

export const PreviewSlideShape = ({
    shape,
    slide,
}: Props) => {

    const getSlideShape = useCallback((type: SlideShapeType) => {

        switch (type) {
            case SlideShapeType.Image: {
                return (
                    <PreviewImageSlideShape
                        slide={slide}
                        shape={shape}
                    />
                );
            }
            case SlideShapeType.Video: {
                return (
                    <PreviewVideoSlideShape
                        slide={slide}
                        fromNavigation={true}
                    />
                );
            }
            case SlideShapeType.Title:
            case SlideShapeType.Content: {
                return (
                    <PreviewTextSlideShape
                        slide={slide}
                        shape={shape}
                    />
                );
            }
            case SlideShapeType.Table: {
                return (
                    <PreviewTableSlideShape
                        slide={slide}
                    />
                );
            }
            case SlideShapeType.BarGraph: {
                return (
                    <PreviewGraphSlideShape
                        slide={slide}
                        graphType={AppChartType.Column}
                    />
                );
            }
            case SlideShapeType.LineGraph: {
                return (
                    <PreviewGraphSlideShape
                        slide={slide}
                        graphType={AppChartType.Line}
                    />
                );
            }
            case SlideShapeType.QuoteSymbol: {
                return (
                    <PreviewQuoteSymbolSlideShape />
                );
            }
            case SlideShapeType.QuoteAuthorSymbol: {
                return (
                    <PreviewQuoteAuthorSlideShape />
                );
            }
            case SlideShapeType.QuizCountdown: {
                return (
                    <PreviewQuizCountdownSlideShape
                        slide={slide}
                    />
                );
            }
            case SlideShapeType.MessagesPanel: {
                return (
                    <PreviewMessagePanelSlideShape />
                );
            }
            case SlideShapeType.WordcloudPanel: {
                return (
                    <PreviewWordcloudSlideShape />
                )
            }
            case SlideShapeType.MpcOptions: {
                return (
                    <PreviewMpcOptionsSlideShape
                        slide={slide}
                    />
                );
            }
            case SlideShapeType.Logo: {
                return (
                    <PreviewLogoSlideShape />
                )
            }
            case SlideShapeType.Leaderboard: {
                return (
                    <PreviewLeaderboardSlideShape />
                )
            }
            case SlideShapeType.Podium: {
                return (
                    <PreviewPodiumSlideShape />
                )
            }
            case SlideShapeType.Explanation: {
                return (
                    <ExplanationSlideShapeComponent from={ViewType.EDITOR}/>
                )
            }
            default: {
                return null;
            }
        }
    }, [
        shape.type,
        shape.contents,
        slide
    ]);

    return (
        <>
            <div
                data-slide-shape
                data-slide-shape-type={shape.type}
                style={{
                    transition: getTransition(shape.type),
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    height: shape.height,
                    width: shape.width,
                    left: shape.x,
                    top: shape.y,
                }}
            >
                {getSlideShape(shape.type)}
            </div >
        </>
    )
}

export default PreviewSlideShape;