import { CircularProgress, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';


interface SessionLoaderProps {
    error: boolean
}

const SessionLoader = ({
    error,
}: SessionLoaderProps) => {

    const { presentationStyle } = usePresentationStyles();

    const theme = useTheme();

    const { translate } = useTranslations()

    return (
        <Grid
            container
            sx={{
                height: '100%',
                width: '100%'
            }}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item sx={{
                width: 500,
                textAlign: 'center',
            }}>
                <Typography  sx={{
                    color: presentationStyle?.textColor,
                    fontSize: 24,
                    padding: 8
                }}>
                    {!error && translate('SETTING_UP_PRESENTATION')}
                    {error && translate('SOMETHING_WENT_WRONG')}
                </Typography>
                {!error && <CircularProgress sx={{
                    color: presentationStyle?.textColor,
                    padding: theme.spacing(2),
                }} />}
            </Grid>
            <Grid item style={{ padding: 16 }}>
            </Grid>
        </Grid>
    );
};

export default SessionLoader;