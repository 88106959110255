//themeVars.color.defaultBg

import { themeVars } from "Components/theme/vanillaTheme.css";
import React, { ReactElement } from "react";
import { classes } from "./style.css";

interface IContainerWithBorder {
    children: ReactElement[] | ReactElement;
    borderColor?: string;
    backgroundColor?: string;
}

const ContainerWithBorder = ({children, borderColor = themeVars.color.defaultBg, backgroundColor = themeVars.color.primaryBg} : IContainerWithBorder)=> {
    return(<div 
                style={{borderColor: borderColor, backgroundColor: backgroundColor}} 
                className={classes.container}>
                    {children}
            </div>);
}
export default ContainerWithBorder;