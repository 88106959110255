import PlayPresentationButton from 'Atomic/molecules/PlayPresentationButton';
import { useAppRouting } from 'Hooks/useAppRouting';
import { ErrorTexts } from 'Scripts/globals';
import { PresentationState } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const SummaryControls = () => {


	const { presentationId } = useAppRouting();

    const slides = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slides);

	const hasNoSlides = isEmpty(slides);

	return (
		<div className={classes.controlsContainer}>
			<PlayPresentationButton
				trackScreen="settings_summary_before_starting_sendsteps"
				trackPosition="summary_header"
				presentationId={presentationId}
				disabled={hasNoSlides}
				tooltip={hasNoSlides && ErrorTexts.PresentationHasNoSlides}
			/>
		</div>
	);
};

export default SummaryControls;