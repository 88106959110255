import Grid from "@mui/material/Grid";
import { themeVars } from "Components/theme/vanillaTheme.css";
import React, { ReactElement } from "react";

interface IThreeColumnsLayout {
    bgColor?: string;
    leftContent: ReactElement;
    rightContent: ReactElement;
    children?: ReactElement;
    columnsConfig?: {
        leftColumn: {
            xs: number;
            sm: number;
            lg: number;
            xl: number;
        },
        centerColumn: {
            xs: number;
            sm: number;
            lg: number;
            xl: number;
        },
        rightColumn: {
            xs: number;
            sm: number;
            lg: number;
            xl: number;
        },
    };
}

const defaultColumnsSizeConfig = {
    leftColumn: {
        xs: 12,
        sm: 3.5,
        lg: 3,
        xl: 3,
    },
    centerColumn: {
        xs: 12,
        sm: 5,
        lg: 6,
        xl: 6,
    },
    rightColumn: {
        xs: 12,
        sm: 3.5,
        lg: 3,
        xl: 3,
    },
};

const ThreeColumnsLayout =({
    bgColor = themeVars.color.primaryBg,
    leftContent,
    rightContent,
    children,
    columnsConfig = defaultColumnsSizeConfig,
}: IThreeColumnsLayout)=> {
    return(
        <Grid container style={{backgroundColor: bgColor}}>
            <Grid xs={columnsConfig.leftColumn.xs} sm={columnsConfig.leftColumn.sm} lg={columnsConfig.leftColumn.lg} xl={columnsConfig.leftColumn.xl} item>{leftContent}</Grid>
            <Grid xs={columnsConfig.centerColumn.xs} sm={columnsConfig.centerColumn.sm} lg={columnsConfig.centerColumn.lg} xl={columnsConfig.centerColumn.xl} item>{children}</Grid>
            <Grid xs={columnsConfig.rightColumn.xs} sm={columnsConfig.rightColumn.sm} lg={columnsConfig.rightColumn.lg} xl={columnsConfig.rightColumn.xl} item>{rightContent}</Grid>
        </Grid>
    );
}
export default ThreeColumnsLayout;