import CropFreeIcon from '@mui/icons-material/CropFree';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import QRComponent from 'Atomic/molecules/QRComponent/QRComponent';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import SessionSMSInfo from '../../../shared_components/session_login_info/SessionSMSInfo';
import { classes } from './style.css';



let GeometrySessionDetails = ({ sessionDetails }) => {

	const { presentationStyle } = usePresentationStyles();

	const { translate } = useTranslations();

	const { internetAddress, internetKeyword } = sessionDetails;

	const QRUrl = `https://${internetAddress}/${internetKeyword}`;

	const participantsWithLoginCodeOnly = sessionDetails.participantsWithLoginCodeOnly ?? false;

	return (
		<Grid
			container
			className={classes.fullSize}
			direction="column"
			justifyContent="center"
			alignItems="center">
			<Grid item>
				<Typography variant="h3" className={classes.joinText} sx={{
					color: presentationStyle?.textColor,

				}}>
					{!participantsWithLoginCodeOnly && translate('GO_TO_SITE_AND_LOGIN', [
						<span className={classes.highlightText}>{sessionDetails.internetAddress}</span>,
						<Typography className={classes.responseCodeText} variant="h2" sx={{
							color: presentationStyle?.textColor,
						}}>
							{sessionDetails.internetKeyword}
						</Typography>
					])}
					{participantsWithLoginCodeOnly && translate('GO_TO_SITE_AND_LOGIN', [
						<span className={classes.highlightText}>{sessionDetails.internetAddress}</span>,
						<span>{translate("YOUR_PERSONAL_LOGIN")} </span>,
					])}
				</Typography>
			</Grid>
			{participantsWithLoginCodeOnly &&
				<Grid item>
					<div className={classes.qrContentFiller}>
					</div>
				</Grid>
			}
			{!participantsWithLoginCodeOnly &&
				<Grid item>
					<div className={classes.qrContent}>
						<QRComponent
							QRCodeText={QRUrl}
						/>
						<Typography className={classes.qrText} variant="body1" sx={{
							color: presentationStyle?.textColor,
						}}>
							{translate('QR_INSTRUCTIONS', [
								<CropFreeIcon className={classes.scanIcon} />
							])}
						</Typography>
					</div>
				</Grid>
			}
			{!participantsWithLoginCodeOnly &&
				<Box
					className={classes.smsInfoContainer}
					sx={{
						color: presentationStyle?.textColor,
					}}>
					<SessionSMSInfo showPrefix={false} />
				</Box>
			}
		</Grid>
	);
};

export default GeometrySessionDetails
