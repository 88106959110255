import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, InputBase, MenuItem, Select, Typography } from '@mui/material';
import { setFlatInputObjValues } from 'Actions/generatePresentationActions';
import { setChangedPresentationParameters } from 'Actions/outlineActions';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import ContainerWithBorder from "Atomic/atoms/ContainerWithBorder/ContainerWithBorder";
import EditInput from 'Atomic/atoms/EditInput/EditInput';
import AppText, { AppTextVariant } from "Atomic/atoms/Text/AppText";
import TwoCtaDialog from 'Atomic/atoms/TwoCtaDialog/TwoCtaDialog';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { useAiLanguages } from 'Hooks/useAiLanguages';
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { AIAmountOfTextOnSlides, AIToneOfVoice, AIWriteFor } from 'Types/aiTypes';
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFlatStepperDataFromLocalStorage } from '../helper';
import { classes } from "./style.css";

const Summary = ()=> {
    const { translatePlaceholder } = useTranslations();
    const {
        isLoading,
    } = useOutline();
    const {
        generateOutline,
    } = useOutline();
    const {
        aiLanguages,
    } = useAiLanguages();
    const dispatch = useDispatch();

    const inputObjValues = useSelector((state: RootStateOrAny) => state.generatePresentationReducer.flatInputObjValues);

    const flatInputObjValues = inputObjValues?.flatInputObjValues || getFlatStepperDataFromLocalStorage();

    const [summaryObj, setSummaryObj] = useState({...flatInputObjValues});
    const [enableInput, setEnableInput] = useState<boolean>(false);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const updateValue = (name: string, value: string|boolean|number)=> {
        const updatedSummaryObj = {...summaryObj};
        updatedSummaryObj[name] = value;
        setSummaryObj(updatedSummaryObj);
        dispatch(setChangedPresentationParameters(true));
    };

    const regenerateOutline = ()=> {
        generateOutline({flatInputObjValues: {...summaryObj}});
        dispatch(setFlatInputObjValues({...summaryObj}));
        setEnableInput(false);
        setDialogIsOpen(false);
    };

    const revertChanges = ()=> {
        setSummaryObj(flatInputObjValues);
        setEnableInput(false);
        dispatch(setChangedPresentationParameters(false));
    };

    const titleInputRef = useRef(null);
    const subjectInputRef = useRef(null);
    const handleInputClick = (ref: React.Ref<HTMLInputElement>) => {
        if (ref?.current?.firstChild) {
            ref.current.firstChild?.select();
        }
    };

    return (
        <Box className={isLoading ? classes.containerLoading : classes.container}>
            <ContainerWithBorder>
                <div className={classes.summaryPageTitle}>
                    <AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("PRESENTATION_PARAMETERS")}
                        <CustomTooltip title={translatePlaceholder('PRESENTATION_PARAMETERS_TOOLTIP')} placement={"top"}>
                            <InfoOutlinedIcon className={classes.infoIcon}/>
                        </CustomTooltip>
                    </AppText>
                </div>
                <Grid container rowGap={1.5} marginBottom={2}>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("TITLE")}:</AppText></Grid>
                    <Grid item xs={6} sm={6}>
                        <EditInput
                            name="presentationTitle"
                            value={summaryObj?.presentationTitle}
                            onChange={(e)=> {updateValue("presentationTitle", e.target.value);setEnableInput(true)}}
                            onClick={()=> handleInputClick(titleInputRef)}
                            inputRef={titleInputRef}
                            className={classes.summaryInput}
                            data-testid={testIds.OUTLINE_TITLE}
							data-elaction={DataElAction.EditTitle}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Icon}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("SUBJECT")}:</AppText></Grid>
                    <Grid item xs={6} sm={6}>
                        <EditInput
                            name="keywords"
                            value={summaryObj?.keywords}
                            onChange={(e)=> {updateValue("keywords", e.target.value);setEnableInput(true)}}
                            onClick={()=> handleInputClick(subjectInputRef)}
                            inputRef={subjectInputRef}
                            className={classes.summaryInput}
                            data-testid={testIds.OUTLINE_SUBJECT}
							data-elaction={DataElAction.EditSubject}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Icon}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("LANGUAGE")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.language}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_LANGUAGE}
							data-elaction={`${DataElAction.ChangeOutlineLanguage}${summaryObj?.language}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                           {(aiLanguages||[]).map((l)=> {
                                return(
                                    <MenuItem
                                        key={l?.isoCode}
                                        value={l?.name}
                                        onClick={()=> {updateValue("language", l?.name);setEnableInput(true)}}
                                    >
                                        <Typography>
                                            {l.name}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("NUMBER_OF_SLIDES")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                                value={summaryObj?.numberOfSlides}
                                input={<InputBase className={classes.dropdown}/>}
                                className={classes.dropdownEnabled}
                                data-testid={testIds.OUTLINE_NUMBER_OF_SLIDES}
                                data-elaction={`${DataElAction.ChangeNumberSlides}${summaryObj?.numberOfSlides}`}
                                data-elscreen={DataElScreen.PresentationParameters}
                                data-eltype={DataElType.Dropdown}
                            >
                                {[4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((n)=> {
                                    return(
                                        <MenuItem
                                            key={n}
                                            value={n}
                                            onClick={()=> {updateValue("numberOfSlides", n);setEnableInput(true)}}
                                        >
                                            <Typography>
                                                {n}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("AMOUNT_OF_TEXT_ON_SLIDES")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.amountOfText}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_AMOUNT_OF_TEXT_ON_SLIDES}
							data-elaction={`${DataElAction.ChangeAmountText}${summaryObj?.amountOfText}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                            {Object.keys(AIAmountOfTextOnSlides).map((amountValue)=> {
                                return(
                                    <MenuItem
                                        key={amountValue}
                                        value={amountValue}
                                        onClick={()=> {updateValue("amountOfText", amountValue);setEnableInput(true)}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + amountValue.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("INCLUDE_SPEAKER_NOTES")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.speakerNotes}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_INCLUDE_SPEAKER_NOTES}
							data-elaction={`${DataElAction.ChangeSpeakernotes}${summaryObj?.speakerNotes}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                            <MenuItem
                                key={"speakerNotesTrue"}
                                value={true}
                                onClick={()=> {updateValue("speakerNotes", true);setEnableInput(true)}}
                            >
                                <Typography>
                                    {translatePlaceholder("YES")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key={"speakerNotesFalse"}
                                value={false}
                                onClick={()=> {updateValue("speakerNotes", false);setEnableInput(true)}}
                            >
                                <Typography>
                                    {translatePlaceholder("NO")}
                                 </Typography>
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("ADD_INTERACTIVE_QUESTIONS")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.interactiveQuestions}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_ADD_INTERACTIVE_QUESTIONS}
							data-elaction={`${DataElAction.ChangeInteraction}${summaryObj?.interactiveQuestions}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                            <MenuItem
                                key={"interactiveQuestionsTrue"}
                                value={true}
                                onClick={()=> {updateValue("interactiveQuestions", true);setEnableInput(true)}}
                            >
                                <Typography>
                                    {translatePlaceholder("YES")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key={"interactiveQuestionsFalse"}
                                value={false}
                                onClick={()=> {updateValue("interactiveQuestions", false);setEnableInput(true)}}
                            >
                                <Typography>
                                    {translatePlaceholder("NO")}
                                 </Typography>
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("WRITE_FOR")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.writeFor}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_WRITE_FOR}
							data-elaction={`${DataElAction.ChangeWriteFor}${summaryObj?.writeFor}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                            {Object.keys(AIWriteFor).map((writeFor)=> {
                                return(
                                    <MenuItem
                                        key={writeFor}
                                        value={writeFor}
                                        onClick={()=> {updateValue("writeFor", writeFor);setEnableInput(true)}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + writeFor.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("TONE_OF_VOICE")}:</AppText></Grid>
                    <Grid item xs={6} sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.toneOfVoice}
                            input={<InputBase className={classes.dropdown}/>}
                            className={classes.dropdownEnabled}
                            data-testid={testIds.OUTLINE_TONE_OF_VOICE}
							data-elaction={`${DataElAction.ChangeTov}${summaryObj?.toneOfVoice}`}
							data-elscreen={DataElScreen.PresentationParameters}
							data-eltype={DataElType.Dropdown}
                        >
                            {Object.keys(AIToneOfVoice).map((toneOfVoice)=> {
                                return(
                                    <MenuItem
                                        key={toneOfVoice}
                                        value={toneOfVoice}
                                        onClick={()=> {updateValue("toneOfVoice", toneOfVoice);setEnableInput(true)}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + toneOfVoice.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={12} xl={6} mb={"10px"}>
                        <div className={classes.regenerateOutlineBox}>
                            {enableInput && <AppButton
                                onClick={()=> revertChanges()}
                                disabled={isLoading}
                                as={AppButtonVariant.Primary}
                                data-testid={testIds.REVERT_CHANGES}
                                data-elaction={DataElAction.RevertChanges}
                                data-elscreen={DataElScreen.PresentationParameters}
                                data-eltype={DataElType.Button}
                            >
                                    {translatePlaceholder("REVERT_CHANGES")}
                            </AppButton>}
                        </div>
                    </Grid>
                    <Grid item lg={12} xl={6} pl={"10px"}>
                        <div className={classes.regenerateOutlineBox}>
                            <AppButton
                                onClick={()=> setDialogIsOpen(true)}
                                disabled={!enableInput || isLoading}
                                as={AppButtonVariant.AiMagic}
                                data-testid={testIds.REGENERATE_OUTLINE}
                                data-elaction={DataElAction.RegenerateOutline}
                                data-elscreen={DataElScreen.PresentationParameters}
                                data-eltype={DataElType.Button}
                            >
                                    {translatePlaceholder("REGENERATE_OUTLINE")}
                            </AppButton>
                        </div>
                    </Grid>
                </Grid>
            </ContainerWithBorder>
            <TwoCtaDialog
                isOpen={dialogIsOpen}
                close={()=> setDialogIsOpen(false)}
                title={translatePlaceholder("DO_YOU_WANT_TO_REGENERATE_THE_OUTLINE") as string}
                leftCtaIcon={<></>}
                leftCtaLabel={translatePlaceholder("YES") as string}
                leftCtaFc={regenerateOutline}
                leftCtaVariant={AppButtonVariant.Success}
                dataTestIdLeftCta={testIds.REGENERATE_OUTLINE_CONFIRM}
                dataElactionLeftCta={DataElAction.RegenerateOutlineConfirm}
                dataElscreenLeftCta={DataElScreen.Popup}
                dataEltypeLeftCta={DataElType.Button}
                dataTestIdRightCta={testIds.REGENERATE_OUTLINE_CANCEL}
                dataElactionRightCta={DataElAction.RegenerateOutlineCancel}
                dataElscreenRightCta={DataElScreen.Popup}
                dataEltypeRightCta={DataElType.Button}
                dataTestIdCloseCta={testIds.REGENERATE_OUTLINE_CLOSE}
                dataElactionCloseCta={DataElAction.RegenerateOutlineClose}
                dataElscreenCloseCta={DataElScreen.Popup}
                dataEltypeCloseCta={DataElType.Button}
            />
        </Box>
    );
}
export default Summary;
